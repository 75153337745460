import { render, staticRenderFns } from "./NewModalBranch.vue?vue&type=template&id=14fc1566&scoped=true"
import script from "./NewModalBranch.vue?vue&type=script&lang=js"
export * from "./NewModalBranch.vue?vue&type=script&lang=js"
import style0 from "./NewModalBranch.vue?vue&type=style&index=0&id=14fc1566&prod&lang=scss&scoped=true"


/* normalize component */
import normalizer from "!../../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "14fc1566",
  null
  
)

export default component.exports